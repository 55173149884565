<template>
  <div class="login-index">
    <div class="big-img" @click="toHome">
      <img :src="bigImg" />
    </div>
    <div class="big-right">
      <div class="info-box">
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import Cookie from "js-cookie"

export default {
  name: "loginIndex",
  data() {
    return {
      bigImg: window.localStorage.getItem("bk_pic"+this.$store.state.indexIdentity),
    };
  },
  created() {
    let code = this.$store.state.indexIdentity;
    let bk_pic = window.localStorage.getItem("bk_pic"+code);
    if (bk_pic) {
      this.bigImg = bk_pic;
    }
    this.$emit("getStatus", false);
  },
  methods: {
    toHome() {
      let code = this.$store.state.indexIdentity;
      this.$router.push({
        path: "/",
        query: {
          ...this.$store.state.query,
          code: code
        }
      });
    }
  },
};
</script>

<style scoped lang="scss">
.login-index {
  position: relative;
  min-width: 1104px;
  background: #f8f8f8;
  display: flex;
  flex-flow: row;
  min-height: 100%;
  .big-img {
    width: (500/1920 * 100%);
    min-width: 350px;
    min-height: 100%;
    img {
      width: 100%;
      min-height: 100%;
    }
  }
  .big-right {
    flex: 1;
    position: relative;
    .info-box {
      width: 520px;
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
